<template>
  <Layout>
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">Bem vindo(a), {{usuario.nome}}!</h4>
                  <p class="text-muted mb-0">
                    Here's what's happening with your store today.
                  </p>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Total Earnings
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <h5 class="text-success fs-14 mb-0">
                        <i class="ri-arrow-right-up-line fs-13 align-middle"></i>
                        +16.24 %
                      </h5>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        $<count-to :startVal='0' :endVal='559' :duration='5000'></count-to>k
                      </h4>
                      <a href="" class="text-decoration-underline">View net earnings</a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-success rounded fs-3">
                        <i class="bx bx-dollar-circle text-success"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row-->
        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>
  </Layout>
</template>

<script>
function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}
import "swiper/swiper-bundle.css";
import "flatpickr/dist/flatpickr.css";
import {
  CountTo
} from "vue3-count-to";

import Layout from "../../../layouts/main.vue";


export default {
  components: {
    CountTo,
    Layout,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
          {
        text: "Dashboards",
        href: "/",
      },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      usuario:{
        nome:'',
      },
      date: null,
      config: {
        mode: "range",
      },
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        labels: ["Direct", "Social", "Email", "Other", "Referrals"],
        chart: {
          height: 333,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        },
        colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'),
      },
    };
  },
  methods: {
    rightcolumn() {
      if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
        document.querySelector('.layout-rightside-col').classList.remove('d-none')
      } else {
        document.querySelector('.layout-rightside-col').classList.add('d-none')
      }
    }
  },
  mounted() {

    try{
      const usuario =  JSON.parse(localStorage.getItem('userdata'));

      this.usuario.nome = usuario.Nome;
    }catch (e){
      console.log(e);
    }
  }

};
</script>